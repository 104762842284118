import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/google-drive-authentication",
  "date": "2014-12-14",
  "title": "GOOGLE DRIVE – AUTHENTICATION",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "When you are developing an application interfacing Google Driver API, it's mandatory to consider how you are going to handle the authentication for your user. In this article, we are discussing how to implement Google Drive Authentication for your app via its API."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Storing Pictures`}</h2>
    <p>{`We’re building an application where users can upload, share, and save pictures. We want to store all our user’s pictures on their personal Drive account.`}</p>
    <p>{`Using the Drive API, we can:`}</p>
    <ul>
      <li parentName="ul">{`Programmatically upload and download files`}</li>
      <li parentName="ul">{`Store application data`}</li>
      <li parentName="ul">{`Manage permissions`}</li>
      <li parentName="ul">{`Receive push notifications`}</li>
      <li parentName="ul">{`And much more ..`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8678/16008089462_898a0cd206_o.png",
        "alt": "Google Drive upload"
      }}></img></p>
    <h2>{`Getting Started`}</h2>
    <p>{`Here are the steps we need to get our app up and running. The steps below will be explained further, but the overview goes as below.`}</p>
    <p>{`On Google’s website...`}</p>
    <ul>
      <li parentName="ul">{`Create a New API Project`}</li>
      <li parentName="ul">{`Enable the Drive API`}</li>
      <li parentName="ul">{`Register a New Application`}</li>
      <li parentName="ul">{`Customize the Consent Screen`}</li>
    </ul>
    <p>{`In our application...`}</p>
    <ul>
      <li parentName="ul">{`Install the Google Client Library`}</li>
      <li parentName="ul">{`Create a Client Object`}</li>
      <li parentName="ul">{`Get Authorization from the User`}</li>
      <li parentName="ul">{`Make API calls to the User’s Drive`}</li>
    </ul>
    <h2>{`We Need to Tell Google About Our App`}</h2>
    <p>{`We need a client ID so communication can happen between our app and each user’s Drive account.`}</p>
    <h2>{`Creating a New API Project`}</h2>
    <p>{`In the `}<strong parentName="p">{`Google Developers Console`}</strong>{`"Google Developers Console, create a new API Project.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7532/16008892235_5d52bd2f01_n.jpg",
        "alt": "New project"
      }}></img></p>
    <h2>{`Enabling the Drive API & SDK`}</h2>
    <p>{`In the sidebar, goto `}<strong parentName="p">{`APIs & auth > APIs`}</strong>{` then enable the `}<strong parentName="p">{`Drive API`}</strong>{` and `}<strong parentName="p">{`Drive SDK`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8659/15822898489_5ab098131a_n.jpg",
        "alt": "APIs & auth"
      }}></img></p>
    <h2>{`Creating a New OAuth Client ID`}</h2>
    <p>{`Goto `}<strong parentName="p">{`APIs & auth > Credentials`}</strong>{` then click `}<strong parentName="p">{`CREATE NEW CLIENT ID`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8652/16008917465_a1ff04ae18_n.jpg",
        "alt": "Credentials"
      }}></img></p>
    <h2>{`Specifying a Redirect URI`}</h2>
    <p>{`Below the OAuth 2.0 credentials, we can specify a Redirect URI. This is the page in our
application that Google will redirect users to after they authenticate.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7516/15389297733_094e56a23f_n.jpg",
        "alt": "Redirect URI"
      }}></img></p>
    <h2>{`The page will now show our newly created Client ID!`}</h2>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7514/15822924949_1bd82653c8_n.jpg",
        "alt": "Client ID"
      }}></img></p>
    <h2>{`Customizing the Consent Screen`}</h2>
    <p>{`On APIs & auth > Consent screen, we can customize the information and logo on the
consent screen the user sees when prompted to authorize our application.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7558/15821545848_fe7ee7ac23_n.jpg",
        "alt": "Consent"
      }}></img></p>
    <h2>{`Installing the Ruby Client Library`}</h2>
    <p>{`Manually install in the gem:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`gem install google-api-client\`\`\`

Or, if using Bundler, add to your Gemfile:

\`\`\`sh
gem 'google-api-client'
`}</code></pre>
    <p>{`Then, require it in your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require 'google/api_client'
`}</code></pre>
    <h2>{`Creating a Client Object`}</h2>
    <p>{`With the initial setup done, we can create a client object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require 'google/api_client'
client = Google::APIClient.new
`}</code></pre>
    <p>{`this is what we’ll be making our API calls on.`}</p>
    <h2>{`Setting Client Credentials`}</h2>
    <p>{`In your application, set the Client ID, Client Secret, and Redirect URI on the client
we created earlier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require 'google/api_client'

client = Google::APIClient.new
client.authorization.client_id = CLIENT_ID
client.authorization.client_secret = CLIENT_SECRET
client.authorization.redirect_uri = REDIRECT_URI
`}</code></pre>
    <p>{`set these to the values
shown in the Console`}</p>
    <h2>{`What do we want access to?`}</h2>
    <p>{`We are about to request access to a user’s Drive account, but we need to specify
what we want access to do?`}</p>
    <ul>
      <li parentName="ul">{`Create, edit, and delete files that your application has created`}</li>
      <li parentName="ul">{`Read only access`}</li>
      <li parentName="ul">{`Full access to all files`}</li>
      <li parentName="ul">{`A few others `}</li>
    </ul>
    <p>{`Authorization scopes are used to request different levels of access to a user’s Drive.`}</p>
    <table>
	<tr><td>**Scope**</td><td>**Meaning**</td></tr>
	<tr><td>https://www.googleapis.com/auth/drive.file</td><td>Per-file access to files uploaded through the API</td></tr>
	<tr><td>https://www.googleapis.com/auth/drive</td><td>Per-file access to files uploaded through the API</td></tr>
	<tr><td>https://www.googleapis.com/auth/drive.readonly</td><td>Read-only access to file metadata and file content</td></tr>
    </table>
    <p>{`Use only if necessary — request the minimum level of access your app needs`}</p>
    <h2>{`Specifying a Scope`}</h2>
    <p>{`We want to be able to read and modify files uploaded by our application, so we’ll use the drive.file scope.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require 'google/api_client'
client = Google::APIClient.new
client.authorization.client_id = CLIENT_ID
client.authorization.client_secret = CLIENT_SECRET
client.authorization.redirect_uri = REDIRECT_URI
client.authorization.scope = 'https://www.googleapis.com/auth/drive.file'
`}</code></pre>
    <h2>{`Multiple Scopes`}</h2>
    <p>{`You can also set multiple scopes. Just make sure you request them all upfront, or else we’ll have to authenticate again.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`client.authorization.scope = [
    'https://www.googleapis.com/auth/drive.file',   
    'https://www.googleapis.com/auth/drive.appdata'
]   
`}</code></pre>
    <h2>{`Prompting the User`}</h2>
    <p>{`Send the user to client.authorization.authorization_uri in their browser.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`redirect_to client.authorization.authorization_uri
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8578/15841099047_1920a18121_n.jpg",
        "alt": "Prompting the User"
      }}></img></p>
    <h2>{`Obtaining an Authorization Code`}</h2>
    <p>{`If the user accepts the authorization prompt, they’ll be sent back to our site at the Redirect URI we set earlier with an authorization code. Set that code on our client object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`# https://example.com/code?state=/profile&code=4/P5qf21TgYWE15-15fWE62
client.code = params[:code] if params[:code]
`}</code></pre>
    <h2>{`Fetching an Access Token`}</h2>
    <p>{`The fetch_access_token! method populates our client object with the access_token, which is what grants us access to the user’s Drive.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`client.code = params[:code] if params[:code]
client.fetch_access_token!
`}</code></pre>
    <p>{`We are now authorized! It also sets the refresh_token (plus expires_in and issued_at), which we can use later to request a new access_token when it expires.`}</p>
    <h2>{`An Easier Way`}</h2>
    <p>{`Libraries are available that simplify authorization, such as the omniauth-google-oauth2 gem by Josh Ellithorpe.`}</p>
    <h2>{`Loading API Definitions`}</h2>
    <p>{`We need to load the Drive API definitions to call Drive API methods.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`drive = client.discovered_api('drive', 'v2')
`}</code></pre>
    <p>{`This drive object we’ve created contains all the methods we can use with the Drive API (e.g. drive.files.insert).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      